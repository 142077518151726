import React from 'react'
import styled from '@emotion/styled'

import IconLink from '../images/ico-external-link.svg'

const Item = styled.div`
    margin-bottom: 1em;
    line-height: 1.5;
    > div > span{
        color: var(--c-black);
        font-weight: 500;
    }
`
const ItemTitle = styled.div`
    display: inline-flex;
    align-items: center;
    > span{
        display: inline-block;
        margin-right: 4px;
    }
    > a{
        display: inline-flex;
        align-items: center;
    }
`

const PortfolioItem = (props) => {
	return(
        <Item>
            <ItemTitle>
                <span>{props.title}</span> 
                { props.url && (
                    <a href={props.url} target="_blank" rel="noopener noreferrer">
                        <img src={IconLink} alt="Open new tab" width="14" height="14"/>
                    </a>
                )}
            </ItemTitle>
            <div><span>Role:</span> {props.role}</div>
            <div><span>Tools:</span> {props.tools}</div>
            { props.preview && <div>Preview: <span dangerouslySetInnerHTML={{__html: props.preview }} /></div> }
            
        </Item>
	)
}

export default PortfolioItem