import React, {useState} from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Sidebar from '../components/sidebar'
import Portfolio from '../components/portfolio'
import PortfolioItem from '../components/portfolioItem'

import styled from '@emotion/styled'

import WorkData from '../data/work.json'

const Container = styled.div`
	padding-left: 0;
	padding-right: 0;
	@media screen and (max-width: 1023px){
		padding-left: 1rem;
		padding-right: 1rem;
	}
`
const SidebarCol = styled.div`
	grid-column: 2 / span 3;
	@media screen and  (max-width: 1023px){
		display: none;
	}
`
const TimeWrapper = styled.div`
	grid-column: 6 / span 6;
	grid-row: 1 / 4;
	padding-left: 1.875rem;
	margin-top: 1.875rem;
	@media screen and (max-width: 767px){
		grid-column: 1 / span 4;
	}
	@media screen and (min-width: 768px) and (max-width: 1023px){
		grid-column: 2 / span 6;
	}
	h4{
		font-size: 1.25em;
		font-weight: 500;
		margin-bottom: 1em;
		color: var(--c-black);
		position: relative;
		display: inline-block;
		padding-bottom: 5px;
		border-bottom: 4px solid var(--c-secondary);
		&:before,
		&:after{
			content: "";
			display: block;
			position: absolute;
		}
		&:before{
			height: 3px;
			width: 21px;
			left: -42px;
			top: 11px;
			background: var(--c-light-grey);
		}
		&:after{
			width: 10px;
			height: 10px;
			border-radius: 100%;
			background: var(--c-primary);
			border: 3px solid var(--c-light-grey);
			left: -25px;
			top: 7px;
		}
	}
	p, ul{
		margin-bottom: 1rem;
	}
	a{
		color: var(--c-tertiary);
		font-weight: 500;
	}
	hr{
		margin: 2rem 0;
	}
`
const ItemMonth = styled.div`
	margin-bottom: 1.5rem;
	padding-left: 0.625rem;
`

const Timeline = () => {

	// Password
	const [name, setName] = useState('')
	return (
		<Layout>
			<SEO pageTitle="Portfolio - What I have done" />
				<Container className="container">
					{
						/*
						size.width > 1023 && <SidebarCol><Sidebar headingText="What&nbsp;&nbsp;&nbsp; I Have Done" /></SidebarCol>
						*/
					}

					<SidebarCol><Sidebar headingText="What&nbsp;&nbsp;&nbsp; I Have Done" /></SidebarCol>

					<TimeWrapper style={{
						borderLeft: name === 'booom' && '3px solid var(--c-light-grey)'
					}}>
						<form>
							<input
								type="password"
								placeholder="Password protected"
								value={name}
								onChange={e => setName(e.target.value)}
							/>
						</form>
						{ name === "booom" &&
							(
								<>
									{
										WorkData.map((data, i) => {
											return(
												<Portfolio year={data.year}>
													{
														data.months.map((month, i) => {
															return(
																<ItemMonth>
																	<h4>{month.node.name}</h4>
																	{
																		month.node.items.map((item, i) => {
																			return(
																				<PortfolioItem
																					title = {item.node.title}
																					url = {item.node.url}
																					role = {item.node.role}
																					tools = {item.node.tools}
																					preview = {item.node.preview}
																				/>
																			)
																		})
																	}
																</ItemMonth>
															)
														})
													}
												</Portfolio>
											)
										})
									}
								</>
							)
					  }
					</TimeWrapper>

				</Container>
		</Layout>
	)
}

export default Timeline

// Hook
// How to use it: const size = useWindowSize()
/*
function useWindowSize() {
	const isClient = typeof window === 'object';
	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined
		};
	}
	const [windowSize, setWindowSize] = useState(getSize);
	useEffect(() => {
		if (!isClient) {
			return false;
		}
		function handleResize() {
			setWindowSize(getSize());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount and unmount
	return windowSize;
}
*/