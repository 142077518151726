import React  from 'react'
import styled from '@emotion/styled'

const Wrap = styled.div`
    margin-bottom: 2rem;
    position: relative;
    &:first-child{
        margin-top: -1.2rem;
    }
    &:before{
        content: "";
        display: block;
        height: 30px;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        background: var(--c-primary);
        border: 5px solid var(--c-light-grey);
        position: absolute;
        left: -40.5px;
        top: 12px;
    }
    h3{
        font-size: 2rem;
        color: var(--c-primary);
        letter-spacing: -1px;
        font-weight: bold;
    }
`

const Portfolio = (props) => {
	return(
        <Wrap>
            <h3>{props.year}</h3>
            {props.children}
        </Wrap>
	)
}

export default Portfolio